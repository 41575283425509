import { Alert, Avatar, Heading } from '@Infowijs-eng/component-library/components'
import { Link, useParams } from 'react-router-dom'
import AppResourceItem from '../components/App/AppResourceItem'
import DetailCardField from '../components/DetailCardField'
import DetailCardFieldGroup from '../components/DetailCardFieldGroup'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import SectionBody from '../components/SectionBody'
import SectionTitle from '../components/SectionTitle'
import useAppData from '../hooks/data/useAppData'

export default function AppDetailScreen() {
  const { bundleId } = useParams()

  const {
    data: app,
    isLoading,
    isError,
    error,
  } = useAppData(bundleId)

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/apps',
            label: 'Apps',
          },
        ]}
        loading={isLoading}
      >
        <Heading fontSize={Heading.size.H3}>
          <div className="flex flex-row gap-4">
            <Avatar image={app?.appResources['images/ic-launcher.png']} alt="App icon" />
            {app?.appMeta?.appName}
          </div>
        </Heading>
      </PageHeader>
      {isError && <Alert errorMessages={[{ title: error }]} />}
      <section aria-labelledby="general information">
        <SectionTitle>General information</SectionTitle>
        <SectionBody className="pt-4">
          <DetailCardFieldGroup>
            <DetailCardField
              loading={isLoading}
              label="Bundle id"
            >
              {app?.bundleId}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="App name"
            >
              {app?.appMeta.appNameDisplay}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="App name - icon"
            >
              {app?.appMeta.appName}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Tag line"
            >
              {app?.appMeta.appTaglineDisplay}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Main customer product"
            >
              <Link to={`/customers/products/${app?.mainCustomerProduct?.id}`}>
                {app?.mainCustomerProduct?.title}
                ↗
              </Link>
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Default language"
            >
              {app?.appMeta?.defaultLanguage}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Show info on login screen"
            >
              {app?.appMeta?.showLoginInfoLink ? 'Yes' : 'No'}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Show news on login screen"
            >
              {app?.appMeta?.showLoginNewsLink ? 'Yes' : 'No'}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Expo project id"
            >
              {app?.appMeta?.expoProjectId}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Expo project slug"
            >
              {app?.appMeta?.expoProjectSlug}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Firebase project number"
            >
              {app?.appMeta?.firebaseProjectNumber}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Firebase project id"
            >
              {app?.firebaseProjectId}
            </DetailCardField>
            <DetailCardField
              loading={isLoading}
              label="Apple app id"
            >
              {app?.appMeta?.appleAppId}
            </DetailCardField>
          </DetailCardFieldGroup>
        </SectionBody>
      </section>
      <section aria-labelledby="assets">
        <SectionTitle>Assets</SectionTitle>
        <SectionBody className="pt-4">
          <div className="grid grid-cols-2">
            {Object.entries(app?.appResources ?? {})
              .map(([key, value]) => (
                <AppResourceItem fileName={key} url={value} key={key} />
              ))}
          </div>
        </SectionBody>
      </section>
    </Layout>
  )
}
