import {
  NavigationItem,
  Sidebar,
  TopBar,
} from '@Infowijs-eng/component-library/components'
import {
  Badge,
  ClockArrowCounterClockwiseCog,
  People,
  Smartphone,
  Temple,
} from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import { useState } from 'react'
import NotificationCenter from './NotificationCenter'

function Layout({ children, contentClassName = null }) {
  const [navigation, toggleNavigation] = useState(false)
  return (
    <div className="bg-white">
      <TopBar onNavigationOpen={() => toggleNavigation(true)} />
      <div className="h-screen mx-auto flex font-sans">
        <Sidebar
          navigationOpen={navigation}
          onNavigationClose={() => toggleNavigation(false)}
          navigationItems={[
            <NavigationItem
              to="/administrators"
              label="Administrators"
              key="administrators"
              Icon={Badge}
              permissions="administrators.list"
            />,
            <NavigationItem
              to="/apps"
              label="Apps"
              key="apps"
              Icon={Smartphone}
              permissions="customers.list"
            />,
            <NavigationItem
              to="/customers"
              label="Customers"
              key="customers"
              Icon={Temple}
              permissions="customers.list"
            />,
            <NavigationItem
              to="/users/search"
              label="Users"
              key="users"
              Icon={People}
              permissions="users.list"
            />,
            <NavigationItem
              to="/importers"
              label="Importers"
              key="importers"
              Icon={ClockArrowCounterClockwiseCog}
            />,
          ]}
        />
        <div className="overflow-auto max-h-screen flex-auto mt-14 lg:mt-0 p-10">
          <div className={cn('max-w-6xl m-auto pb-24', contentClassName)}>
            {children}
          </div>
        </div>
      </div>
      <NotificationCenter />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
  contentClassName: cn.propType,
}

Layout.defaultProps = {
  children: null,
  contentClassName: null,
}

export default Layout
