import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { AuthenticationFrame } from '@Infowijs-eng/component-library/modules/authentication'
import isDevelopment from '@Infowijs-eng/component-library/modules/isDevelopment'
import ApiHeadersContext from '@Infowijs-eng/component-library/modules/ApiHeadersContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import getEnvironment from './modules/environment'

import Routes from './routes'
import '@Infowijs-eng/component-library/theme/infowijs.css'

import {
  persistor,
  store,
} from './store'

if (
  !isDevelopment
  && navigator.userAgent.toLowerCase()
    .indexOf('safari') > -1
  && navigator.userAgent.toLowerCase()
    .indexOf('chrome') === -1
  && process.env.NODE_ENV === 'development'
) {
  // eslint-disable-next-line no-console
  console.error('Please use another browser to run this client in Development Mode with a Staging backend')
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://fb636e3030b64f96b08d77aa133c2383@o97448.ingest.sentry.io/5698569',
    // eslint-disable-next-line no-nested-ternary
    environment: getEnvironment(),
    release: `infowijs-admin-client@${process.env.VERSION}`,
  })
}

const root = createRoot(document.getElementById('root'))

const queryClient = new QueryClient()

root.render(
  <StrictMode>
    <ApiHeadersContext.Provider value={{
      Accept: 'application/vnd.infowijs.v1+json',
      'X-Infowijs-Client': 'nl.infowijs.admin',
      'Content-Type': 'application/vnd.infowijs.v1+json',
    }}
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={<div>Wow. Hold on.</div>} persistor={persistor}>
            <AuthenticationFrame client="nl.infowijs.admin" />
            <Routes />
          </PersistGate>
        </Provider>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </ApiHeadersContext.Provider>
  </StrictMode>,
)
