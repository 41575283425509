import PropTypes from 'prop-types'
import React from 'react'
import ListItem from '../ListItem'

function AppListItem({
  app,
}) {
  return (
    <ListItem
      to={`/apps/${app.bundleId}`}
    >
      <span className="flex flex-col">
        <span className="font-medium font-display">
          {app.appMeta.appNameDisplay}
        </span>
        <span className="text-gray-500">
          {app.mainCustomerProduct.title}
        </span>
      </span>
    </ListItem>
  )
}

AppListItem.propTypes = {
  app: PropTypes.shape({
    bundleId: PropTypes.string.isRequired,
    beta: PropTypes.bool,
    mainCustomerProduct: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    appMeta: PropTypes.shape({
      appNameDisplay: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

AppListItem.defaultProps = {}

export default AppListItem
