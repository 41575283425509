import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import fetchApps from '../../actions/apps/fetchApps'
import setApps from '../../actions/apps/setApps'
import awaitAccessToken from '../../modules/awaitAccessToken'

export default function* onFetchApps() {
  yield takeLatest(actionTypes.APPS.FETCH_ALL, function* onfetchApps(action) {
    const accessToken = yield call(awaitAccessToken)

    const {
      meta: {
        page,
        limit,
      },
    } = action

    const params = {
      include: 'customerProduct',
      page: page || 1,
      limit,
    }

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}/apps`,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchApps() {
          yield put(fetchApps({ page, limit }))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(setApps(response.data.data || [], response.data.meta, response.data.included || {}))

    acceptAction(action, response.data.data || [])
  })
}
