import { Document, NoPhoto } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

function AppResourceItem({
  fileName,
  url,
}) {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (!fileName.startsWith('images/')) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            setHasError(true)
          }
        })
        .catch(() => setHasError(true))
    }
  }, [fileName])

  let item
  if (hasError) {
    item = <NoPhoto className="w-40 h-40 p-8 text-gray-500 border rounded-md" />
  } else if (fileName.startsWith('images/')) {
    item = <img className="w-40 h-40 border rounded-md" src={url} alt={fileName} onError={() => setHasError(true)} />
  } else {
    item = <Document className="w-40 h-40 p-8 text-gray-500 border rounded-md" />
  }

  return (
    <div className="flex justify-center items-center flex-col" key={fileName}>
      <span className={cn('font-bold', hasError && 'text-red-700')}>{fileName}</span>
      {item}
    </div>
  )
}

AppResourceItem.propTypes = {
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

AppResourceItem.defaultProps = {}

export default AppResourceItem
