export default function readTextFromFormFileInput(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target?.result?.toString())
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsText(file)
  })
}
