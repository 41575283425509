import {
  Button, FormCard, FormField, FormFieldLabel, Toggle,
} from '@Infowijs-eng/component-library/components'
import { ArrowsLoop, FloppyDisk } from '@Infowijs-eng/component-library/icons'
import { useCallback, useState } from 'react'
import { ZodError, ZodType, z } from 'zod'
import readTextFromFormFileInput from '../../modules/readTextFromFormFileInput'

const appDataValidator = z.strictObject({
  appName: z.string().min(1, 'App name is required'),
  appNameDisplay: z.string().min(1, 'App name display is required'),
  appTaglineDisplay: z.string().min(1).optional(),
  customerProductId: z.string().uuid('Customer product ID must be a valid UUID'),
  defaultLanguage: z.enum(['en', 'nl'], { message: 'Default language must be either "en" or "nl"' }),
  showLoginInfoLink: z.boolean(),
  showLoginNewsLink: z.boolean(),
  expoProjectId: z.string().uuid('Expo Project Id must be a valid UUID'),
  expoProjectSlug: z.string().min(1, 'Expo Project Slug is required'),
  firebaseProjectNumber: z.string()
    .min(1, 'Firebase Project Number is required')
    .regex(/^\d+$/, 'Firebase Project Number must be a number'),
  appleAppId: z.string().min(1, 'Apple App ID is required'),
})

const googleServiceAccountCredentialsValidator = z.strictObject({
  type: z.literal('service_account'),
  auth_uri: z.string().url(),
  client_id: z.string().min(1).regex(/^\d+$/),
  token_uri: z.string().url(),
  project_id: z.string().min(1),
  private_key: z.string().min(1),
  client_email: z.string().email(),
  private_key_id: z.string().min(1),
  universe_domain: z.string().min(1),
  client_x509_cert_url: z.string().url(),
  auth_provider_x509_cert_url: z.string().url(),
})

export type CreateAppData = ZodType<typeof appDataValidator> & {
  googleServiceAccountCredentials: ZodType<typeof googleServiceAccountCredentialsValidator>
}

export interface AppFormProps {
  onSubmit: (data: CreateAppData) => void
  onError: (errors: { title: string }[]) => void
  submitting?: boolean
  loading?: boolean
}

export default function AppForm({
  onSubmit,
  submitting = false,
  onError,
  loading = false,
}: AppFormProps) {
  const [showLoginInfoLink, setShowLoginInfoLink] = useState(true)
  const [showLoginNewsLink, setShowLoginNewsLink] = useState(true)

  const onFormSubmit = useCallback((e) => {
    e.preventDefault()
    const asyncAction = async () => {
      const formData = new FormData(e.target)

      const appName = formData.get('appName')
      const appNameDisplay = formData.get('appNameDisplay')
      let appTaglineDisplay = formData.get('appTaglineDisplay')
      if (appTaglineDisplay === '') {
        appTaglineDisplay = undefined
      }
      const customerProductId = formData.get('customerProductId')
      const defaultLanguage = formData.get('defaultLanguage')
      const expoProjectId = formData.get('expoProjectId')
      const expoProjectSlug = formData.get('expoProjectSlug')
      const firebaseProjectNumber = formData.get('firebaseProjectNumber')
      const appleAppId = formData.get('appleAppId')
      const appData = {
        appName,
        appNameDisplay,
        appTaglineDisplay,
        customerProductId,
        defaultLanguage,
        showLoginInfoLink,
        showLoginNewsLink,
        expoProjectId,
        expoProjectSlug,
        firebaseProjectNumber,
        appleAppId,
      }

      const errors = []
      let validatedData
      try {
        validatedData = appDataValidator.parse(appData)
      } catch (error) {
        if (error instanceof ZodError) {
          error.errors?.forEach((err) => errors.push({ title: err.message }))
        }
      }

      try {
        const googleServiceAccountCredentialsFile = formData.get('googleServiceAccountCredentials')
        if (!(googleServiceAccountCredentialsFile instanceof File)) {
          throw new Error('Google service account credentials must be a file')
        }
        validatedData.googleServiceAccountCredentials = googleServiceAccountCredentialsValidator.parse(
          JSON.parse(await readTextFromFormFileInput(googleServiceAccountCredentialsFile)),
        )
      } catch (error) {
        errors.push({ title: 'Could not read Google service account credentials' })
      }

      if (errors.length) {
        onError(errors)
        return
      }

      onSubmit(validatedData)
    }
    asyncAction()
      .then()
  }, [
    showLoginInfoLink,
    showLoginNewsLink,
  ])

  return (
    <form
      className="space-y-6"
      onSubmit={onFormSubmit}
    >
      <FormCard
        label="General Information"
        hint="We want to know all of these things about an app"
      >
        <FormField
          required
          label="App name"
          name="appName"
        />
        <FormField
          required
          label="App name display"
          name="appNameDisplay"
        />
        <FormField
          label="Tagline"
          name="appTaglineDisplay"
        />
        <FormField
          required
          label="Customer product ID"
          name="customerProductId"
        />
        <FormField
          required
          label="Default language"
          name="defaultLanguage"
        />
        <div className="col-span-6 sm:col-span-4">
          <Toggle
            label="Show info on login screen"
            name="showLoginInfoLink"
            enabled={showLoginInfoLink}
            onChange={setShowLoginInfoLink}
          />
        </div>
        <div className="col-span-6 sm:col-span-4">
          <Toggle
            label="Show news on login screen"
            name="showLoginNewsLink"
            enabled={showLoginNewsLink}
            onChange={setShowLoginNewsLink}
          />
        </div>
        <FormField
          required
          label="Expo project ID"
          name="expoProjectId"
        />
        <FormField
          required
          label="Expo project slug"
          name="expoProjectSlug"
        />
        <FormField
          required
          label="Firebase project number"
          name="firebaseProjectNumber"
        />
        <FormField
          required
          label="Apple app ID"
          name="appleAppId"
        />
        <div className="col-span-6 sm:col-span-4">
          <FormFieldLabel name="googleServiceAccountCredentials">
            Google service account credentials
            <span className="ml-1 text-red-300">*</span>
          </FormFieldLabel>
          <input type="file" name="googleServiceAccountCredentials" />
        </div>
      </FormCard>

      <div className="flex justify-end space-x-3">
        <Button
          type="submit"
          primary
          disabled={submitting || loading}
          leadingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <FloppyDisk />}
        >
          Create app
        </Button>
      </div>
    </form>
  )
}
