import actionTypes from '../action-types'

const initialState = {
  data: [],
  meta: {
    limit: 50,
    pages: 1,
  },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.APPS.SET_ALL:
      return {
        ...state,
        data: action.payload.map((app) => ({
          ...app,
          mainCustomerProduct: action.included?.customerProduct?.[app.mainCustomerProduct.id] || null,
        }), {}),
        meta: {
          ...state.meta,
          ...action.meta,
          pages: Math.ceil(((action.meta && action.meta.total) || 0) / ((action.meta && action.meta.limit) || 50)),
        },
      }
    default:
      return state
  }
}
