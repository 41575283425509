import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import { useQuery } from '@tanstack/react-query'

export default function useAppData(bundleId) {
  const request = useAuthenticatedRequest({}, serviceEndpoints.customers)
  return useQuery({
    queryKey: ['app', bundleId],
    queryFn: async () => request(`/apps/${bundleId}`, {
      params: { include: 'customerProduct' },
    }).then((response) => {
      const mainCustomerProduct = response?.included?.customerProduct?.[response?.data?.mainCustomerProduct?.id] ?? null
      return {
        ...response.data,
        mainCustomerProduct,
      }
    }),
  })
}
