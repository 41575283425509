import actionTypes from '../../action-types'

export default ({
  page, limit,
}) => ({
  type: actionTypes.APPS.FETCH_ALL,
  meta: {
    page,
    limit,
  },
})
