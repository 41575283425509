import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import fetchCustomers from '../../actions/customers/fetchCustomers'
import setCustomers from '../../actions/customers/setCustomers'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchCustomers = function* onFetchCustomers() {
  yield takeLatest(actionTypes.CUSTOMERS.FETCH_ALL, function* onfetchCustomers(action) {
    const accessToken = yield call(awaitAccessToken)

    const {
      meta: {
        page,
        filter,
        sort,
        limit,
      },
    } = action

    const params = {
      include: 'administrator,customerProduct',
      page: page || 1,
      sort: sort || 'title:asc',
      limit,
      ...filter,
    }

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}`,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchCustomers() {
          // TODO: This must be broken, but fixing it means extra testing, so please do this later
          yield put(fetchCustomers(page, filter))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(setCustomers(response.data.data || [], response.data.meta, response.data.included || []))

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchCustomers
