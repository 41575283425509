import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateAppData } from '../../components/App/AppForm'

export default function useCreateNewApp() {
  const queryClient = useQueryClient()
  const fetch = useAuthenticatedRequest({}, serviceEndpoints.customers)
  return useMutation({
    mutationFn: (data: CreateAppData) => fetch('/apps', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/vnd.infowijs.v1+json',
      },
      data,
    }),
    onSuccess: (data: { data: { bundleId: string } }) => queryClient.invalidateQueries({
      queryKey: ['apps', data.data.bundleId],
    }),
  })
}
