import { Alert, Heading } from '@Infowijs-eng/component-library/components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppForm from '../components/App/AppForm'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import useCreateNewApp from '../hooks/data/useCreateNewApp'

function AppCreateScreen() {
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const navigate = useNavigate()
  const createNewApp = useCreateNewApp()

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/apps',
            label: 'Apps',
          },
        ]}
      >
        <Heading fontSize={Heading.size.H3}>Create a new app</Heading>
      </PageHeader>

      <div className="sticky top-0 z-50">
        {errorMessages && !!errorMessages.length && (<Alert title="You made a booboo" errorMessages={errorMessages} />)}
      </div>

      <AppForm
        submitting={submitting}
        onError={(errors) => setErrorMessages(errors)}
        onSubmit={(data) => {
          setSubmitting(true)
          setErrorMessages(null)

          createNewApp.mutate(data, {
            onSuccess: (responseData: { data: { bundleId: string } }) => {
              navigate(`/apps/${responseData.data.bundleId}`)
            },
            onError: (error) => {
              setErrorMessages([{ title: `Could not create app: ${error}` }])
            },
          })
        }}
      />
    </Layout>
  )
}

AppCreateScreen.defaultProps = {}

export default AppCreateScreen
