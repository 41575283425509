import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import fetchApps from '../actions/apps/fetchApps'
import AppListItem from '../components/App/AppListItem'
import AppListSkeleton from '../components/App/AppListSkeleton'
import Layout from '../components/Layout'
import List from '../components/List'
import ListItem from '../components/ListItem'
import PageHeader from '../components/PageHeader'
import Pagination from '../components/Pagination'
import usePagination from '../modules/usePagination'
import getAppsData from '../selectors/apps/getAppsData'
import getAppsMeta from '../selectors/apps/getAppsMeta'

function AppsScreen() {
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [searchParams] = useSearchParams()

  const apps = useSelector(getAppsData)

  const meta = useSelector(getAppsMeta)
  const [page, pages, total] = usePagination(meta)

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchApps({
      page,
      limit: 100,
    }))
      .then(() => setLoading(false))
      .catch(setErrorMessages)
      .finally(() => setLoading(false))
  }, [searchParams, fetchApps])

  return (
    <Layout>
      <PageHeader
        actions={[{
          primary: true,
          children: 'New App',
          leadingAddon: <PlusCircle />,
          to: '/apps/new',
          permissions: ['customers.list'],
          key: 'create',
        }]}
        loading={loading}
      >
        <Heading fontSize={Heading.size.H3}>Apps</Heading>
      </PageHeader>
      {!!errorMessages && <Alert errorMessages={errorMessages} />}
      <List>
        {loading && (!apps || !apps.length) && <AppListSkeleton />}
        {!loading && !apps.length && <ListItem className="italic gray-500">No results</ListItem>}
        {!!apps.length && apps.map((app) => (
          <AppListItem
            key={app.id}
            app={app}
          />
        ))}
      </List>
      {total > 0 && (
        <div className="text-sm text-gray-300 italic flex justify-end mx-2">
          <div>{`There are ${total} results`}</div>
        </div>
      )}
      {pages > 1 && (<Pagination current={page || 1} total={pages} />)}
    </Layout>
  )
}

export default AppsScreen
