import {
  PrivateScreen,
  getRandomSessionIdentifier,
  isIframeReady,
  startAccessTokenRefresh,
} from '@Infowijs-eng/component-library/modules/authentication'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import AppDetailScreen from './screens/AppDetailScreen'
import AppCreateScreen from './screens/AppCreateScreen.tsx'
import AppsScreen from './screens/AppsScreen'
import ImporterMigrateScreen from './screens/ImporterMigrateScreen.tsx'
import AdministratorDetailScreen from './screens/AdministratorDetailScreen'
import AdministratorEditScreen from './screens/AdministratorEditScreen'
import AdministratorInviteScreen from './screens/AdministratorInviteScreen'
import AdministratorsScreen from './screens/AdministratorsScreen'
import CustomerCreateScreen from './screens/CustomerCreateScreen'
import CustomerDetailScreen from './screens/CustomerDetailScreen'
import CustomerEditScreen from './screens/CustomerEditScreen'
import CustomerProductCreateScreen from './screens/CustomerProductCreateScreen'
import CustomerProductDetailScreen from './screens/CustomerProductDetailScreen'
import CustomerProductEditScreen from './screens/CustomerProductEditScreen'
import CustomerProductUserGroupsScreen from './screens/CustomerProductUserGroupsScreen'
import CustomersScreen from './screens/CustomersScreen'
import CustomerUserRolesScreen from './screens/CustomerUserRolesScreen'
import CustomerUsersScreen from './screens/CustomerUsersScreen'
import ImporterOverviewScreen from './screens/ImporterOverviewScreen'
import MagicLinkScreen from './screens/MagicLinkScreen'
import SignInScreen from './screens/SignInScreen'
import SignOutScreen from './screens/SignOutScreen'
import UserCreateScreen from './screens/UserCreateScreen'
import UserDetailScreen from './screens/UserDetailScreen'
import UserEditScreen from './screens/UserEditScreen'
import UserGroupRelationsScreen from './screens/UserGroupRelationsScreen'
import UserNotificationsScreen from './screens/UserNotificationsScreen.tsx'
import UserRelationsScreen from './screens/UserRelationsScreen'
import UsersOverviewScreen from './screens/UsersOverviewScreen'

export default function routes() {
  const isAuthenticationFrameLoaded = useSelector(isIframeReady)
  const currentRsi = useSelector(getRandomSessionIdentifier)
  const previousRsiRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (previousRsiRef !== currentRsi && isAuthenticationFrameLoaded) {
      dispatch(startAccessTokenRefresh())
      previousRsiRef.current = currentRsi
    }
  }, [currentRsi, isAuthenticationFrameLoaded])

  return (
    <BrowserRouter>
      <Routes>
        {/* AUTHENTICATION */}
        <Route path="/magic-link/*" element={<MagicLinkScreen />} />
        <Route path="/sign-in" element={<SignInScreen />} />
        <Route path="/sign-out" element={<SignOutScreen />} />

        {/* ADMINISTRATORS */}
        <Route
          path="/administrators"
          element={<PrivateScreen screen={<AdministratorsScreen />} permissions="administrators.list" />}
        />
        <Route
          path="/administrators/invite"
          element={<PrivateScreen screen={<AdministratorInviteScreen />} permissions="administrators.invite" />}
        />
        <Route
          path="/administrators/:administratorId"
          element={<PrivateScreen screen={<AdministratorDetailScreen />} />}
        />
        <Route
          path="/administrators/:administratorId/edit"
          element={(
            <PrivateScreen
              screen={<AdministratorEditScreen />}
              permissions={[
                'administrators.list',
                'administrators.edit',
              ]}
            />
          )}
        />

        {/* CUSTOMER PRODUCTS */}
        <Route
          path="/customers/products/:customerProductId/edit"
          element={
            <PrivateScreen screen={<CustomerProductEditScreen />} permissions="customers.customerProducts.edit" />
          }
        />
        <Route
          path="/customers/products/:customerProductId/user-groups"
          element={<PrivateScreen screen={<CustomerProductUserGroupsScreen />} permissions="customers.view" />}
        />
        <Route
          path="/customers/products/:customerProductId"
          element={<PrivateScreen screen={<CustomerProductDetailScreen />} permissions="customers.view" />}
        />

        {/* APPS */}
        <Route
          path="/apps/new"
          element={<PrivateScreen screen={<AppCreateScreen />} permissions="customers.list" />}
        />
        <Route
          path="/apps/:bundleId"
          element={<PrivateScreen screen={<AppDetailScreen />} permissions="customers.list" />}
        />
        <Route
          path="/apps"
          element={<PrivateScreen screen={<AppsScreen />} permissions="customers.list" />}
        />
        {/* CUSTOMERS */}
        <Route
          path="/customers/new"
          element={(
            <PrivateScreen
              screen={<CustomerCreateScreen />}
              permissions={[
                'customers.list',
                'customers.create',
              ]}
            />
          )}
        />
        <Route
          path="/customers/:customerId/products/create"
          element={(
            <PrivateScreen
              screen={<CustomerProductCreateScreen />}
              permissions="customers.customerProducts.create"
            />
        )}
        />
        <Route
          path="/customers/:customerId/edit"
          element={(
            <PrivateScreen
              screen={<CustomerEditScreen />}
              permissions={[
                'customers.view',
                'customers.edit',
              ]}
            />
          )}
        />
        <Route
          path="/customers/:customerId/roles"
          element={<PrivateScreen screen={<CustomerUserRolesScreen />} permissions="users.list" />}
        />
        <Route
          path="/customers/:customerId/users/new"
          element={<PrivateScreen screen={<UserCreateScreen />} permissions="users.create" />}
        />
        <Route
          path="/customers/:customerId/users/:userId/relations/*"
          element={<PrivateScreen screen={<UserRelationsScreen />} permissions="users.view" />}
        />
        <Route
          path="/customers/:customerId/users/:userId/user-groups/*"
          element={<PrivateScreen screen={<UserGroupRelationsScreen />} permissions="users.view" />}
        />
        <Route
          path="/customers/:customerId/users/:userId/notifications/*"
          element={
            <PrivateScreen screen={<UserNotificationsScreen />} permissions={['users.view', 'notifications.view']} />
          }
        />
        <Route
          path="/customers/:customerId/users/:userId/edit"
          element={(
            <PrivateScreen
              screen={<UserEditScreen />}
              permissionsWithRegex={/users\.edit\.(manual|imported)/}
            />
          )}
        />
        <Route
          path="/customers/:customerId/users/:userId"
          element={<PrivateScreen screen={<UserDetailScreen />} permissions="users.view" />}
        />
        <Route
          path="/customers/:customerId/users"
          element={<PrivateScreen screen={<CustomerUsersScreen />} permissions="users.list" />}
        />
        <Route
          path="/customers/:customerId/importers/migrate/*"
          element={(
            <PrivateScreen
              screen={<ImporterMigrateScreen />}
              permissions={['importers.create', 'users.create', 'users.edit.imported']}
            />
          )}
        />
        <Route
          path="/customers/:customerId/*"
          element={(
            <PrivateScreen
              screen={<CustomerDetailScreen />}
              permissions={[
                'customers.list',
                'customers.view',
              ]}
            />
          )}
        />
        <Route
          path="/customers"
          element={<PrivateScreen screen={<CustomersScreen />} permissions="customers.list" />}
        />

        {/* USERS */}
        <Route
          path="/users/search"
          element={<PrivateScreen screen={<UsersOverviewScreen />} permissions="users.view" />}
        />

        <Route path="/importers" element={<ImporterOverviewScreen />} />

        <Route path="/*" element={<Navigate to="/customers" />} />

      </Routes>
    </BrowserRouter>
  )
}
